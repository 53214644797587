@media (max-width: 1000px) {
    /* .dasboard-view {
        padding-left: 0 !important;
        padding-right: 0 !important;
    } */
    .dashboard-loan-title {
        display: flex;
        justify-content: center;
    }
    .table-view {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .general-buttom-dashboard {
        border-radius: 0 !important;
    }
    .general-dashboard {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important;
    }
}

@media (max-width: 767.9px) {
    .text-alapar-img {
        display: none !important;
    }
    .images-navbar-general {
        position: absolute !important;
    }
    .navbar-general {
        min-height: 0 !important;
        margin-top: 0.2rem !important;
    }
}

@media (max-width: 600px) {
    .adelanto-text {
        margin-top: 1rem !important;
    }
    .status-title {
        height: 2.5rem !important;
    }
}